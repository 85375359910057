<template>
  <v-card id="account-setting-card">
    <div id="Music" cols="12" sm="12" style="padding: 30px">
      <h1>FlashLight</h1>
      <v-switch v-model="switch1" inset :label="`Want to turn on flashlight ?`"></v-switch>

      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <center style="font-size: 40px">
                <v-btn color="success" class="mr-4" @click="saveFlashLight()">Save & Play</v-btn>
                <br />
              </center>
            </v-col>
          </v-row>
          <v-snackbar v-model="snackbar" :timeout="timeout" :right="x === 'right'" :top="y === 'top'" :color="color">
            {{ text }}
            <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
        </v-container>
      </v-form>
    </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
// import { appConfig } from "../../firebaseConfig";
import { doc, getDoc } from 'firebase/firestore'
import { dbRef, flashlightClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
import { setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'FlashLight',
  data() {
    return {
      switch1: false,
      length: 5,
      valid: false,
      search: '',
      musicUrl: '',
      musicRules: [v => !!v || 'Music URL is required', v => /\.(?:wav|mp3)$/i.test(v) || 'Music must be valid'],
      selected: null,
      notificationValues: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      myitems: [],
      latitude: '',
      longitude: '',
      obj: {
        appVibration: {
          seconds: '',
          vibration: '',
        },
        battryPercentage: '',
        isCharging: '',
        bluetooth: '',
        brightness: '',
        flashlight: '',
        mobileData: '',
        mobileDataType: '',
        musicUrl: {
          music: '',
          url: '',
        },
        sound: '',
      },
      color: '',
      mode: '',
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: 'top',
    }
  },
  mounted: function () {
    flashlightClick()
    this.getAppConfig()
  },
  methods: {
    async getAppConfig() {
      this.$store.commit('LOADER', true)
      let uid = getUID()
      const myDoc = doc(dbRef, 'AppConfig', uid, getCurrentDeviceSelected(), uid + '-' + getCurrentDeviceSelected())
      getDoc(myDoc)
        .then(res => {
          console.log(res)
          this.obj = res.data()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
    async saveFlashLight() {
      this.$store.commit('LOADER', true)
      let deviceID = getCurrentDeviceSelected()
      let uid = getUID()
      let uuids = uuidv4()
      console.log(this.selected)
      const docData = {
        actionType: 'flashlight',
        createdDate: new Date().toDateString(),
        createdTS: new Date().getTime(),
        deviceId: deviceID,
        docId: uuids,
        uid: uid,
        updateDate: new Date().toDateString(),
        updatedTs: new Date().getTime(),
        status: 'pending',
      }
      console.log(docData)
      await setDoc(doc(dbRef, 'action', uuids), docData)
        .then(res => {
          console.log(res)
          this.text = 'successfully Changes'
          this.snackbar = true
          this.color = 'success'
        })
        .catch(error => {
          console.log(error)
          this.text = 'Something went wrong.'
          this.snackbar = true
          this.color = 'failed'
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
  },
}
</script>